import axios from "axios";
import { Animated } from "react-animated-css";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlackBall from "./Balls/BlackBall";
import YellowBall from "./Balls/YellowBall";
import RedBall from "./Balls/RedBall";
import Loader from "./Loader/Loader";
import EyeIcon from "./EyeIcon/EyeIcon";
import { formatDate } from "../tools/format";
import WhiteBall from "./Balls/WhiteBall";
import GreenBall from "./Balls/GreenBall";
import Ganador from "./Ganador/Ganador";
import NumberFormat from "react-number-format";
import NoDisponible from "../img/defaultImageError.png";
import Alert from "./Alert/Alert";
import "./CuponesPendientes/CuponesPendientes.css";
import { checkOrgAndNotLoggedIn } from "../utils/generalUtils";

const MODALITY_VALUE = {
  COM: ["Tradicional"],
  DES: ["Tradicional", "Revancha"],
  SSS: ["Tradicional", "Revancha", "Siempre Sale"],
};
const TraerCuponesPendientesLotoides = (props) => {
  const ordenQuini6 = [
    "Tradicional",
    "La Segunda",
    "Revancha",
    "Siempre Sale",
    "Pozo Extra",
  ];
  const ordenLotoPlus = [
    "Número Plus",
    "Tradicional",
    "Match",
    "Desquite",
    "Sale o Sale",
  ];
  const [isHovered, setIsHovered] = useState(false);
  const [url, setUrl] = useState();
  const [cupones, setCupones] = useState([]);
  const codigoOrg = window.parent.getCodigoOrganizacion();
  const [logoJuego, setLogoJuego] = useState(undefined);
  const [stateProximos, setStateProximos] = useState(false);
  const [stateHistorico, setStateHistorico] = useState(false);
  const [loader, setLoader] = useState(false);
  const [jugadaFavorita, setJugadaFavorita] = useState(undefined);
  const [jugadaActual, setJugadaActual] = useState(undefined);
  const [extracto, setExtracto] = useState(undefined);
  const [alert, setAlert] = useState(undefined);
  const [cantCuponesPendientes, setCantCuponesPendientes] = useState(undefined);

  const isOrgAndNotLoggedIn = checkOrgAndNotLoggedIn(
    ["53", "23"],
    props.estadoPlataforma.logueado
  );

  const cargarImgDinamica = (game) => {
    if (game === "quini6") {
      import(`../img/LOGO_JUEGO_4.svg`)
        .then((image) => {
          setLogoJuego(image.default);
        })
        .catch(() => {
          setLogoJuego(NoDisponible);
        });
    } else {
      import(`../img/LOGO_JUEGO_3.svg`)
        .then((image) => {
          setLogoJuego(image.default);
        })
        .catch(() => {
          setLogoJuego(NoDisponible);
        });
    }
  };
  const traerFavorita = async () => {
    if (isOrgAndNotLoggedIn) return;

    let url = "";

    if (props.juego === "quini6") {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_QUINI6_FAVORITA;
    }
    if (props.juego === "lotoplus") {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_LOTOPLUS_FAVORITA;
    }

    try {
      const response = await axios.get(
        `${url}?codigoJuego=${props.juego === "quini6" ? 4 : 3}`,
        {
          headers: {
            Authorization: `Bearer ${props.estadoPlataforma.userToken}`,
          },
        }
      );

      if (response.status === 200) {
        const { numeros, numerosExtra } = response.data;
        setJugadaFavorita({ numeros, numerosExtra });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const traerCupones = async (url) => {
    if (isOrgAndNotLoggedIn) return;

    setLoader(true);
    return await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${props.estadoPlataforma.userToken}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setTimeout(() => {
          setCupones(data);
          setLoader(false);
        }, 1000);
      })
      .catch((err) => console.error(err));
  };

  const traerCupon = async (url) => {
    return await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${props.estadoPlataforma.userToken}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setJugadaActual(data);
      })
      .catch((err) => console.error(err));
  };
  const verDetalle = (cupon) => {
    let url = "";
    if (props.juego === "quini6") {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_QUINI6_CUPON;
    }
    if (props.juego === "lotoplus") {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_LOTOPLUS_CUPON;
    }
    url = url.replace(":cuponId", cupon.cuponId);
    url = url.replace(":nApins", cupon.nApins);
    url = url.replace(":nApues", cupon.nApues);
    url = url.replace(":procesado", stateHistorico);
    url = url.replace(":codOrg", window.getCodigoOrganizacion());

    traerCupon(url);
  };

  const verHistoricos = () => {
    setStateHistorico(true);
    setStateProximos(false);
  };

  const verProximos = () => {
    setStateHistorico(false);
    setStateProximos(true);
  };

  const volverACupones = () => {
    setJugadaActual(undefined);
  };
  const volverAJugada = () => {
    setExtracto(undefined);
  };

  const verExtracto = (extractoCupon) => {
    let extractoOrdenado;
    if (props.juego === "quini6") {
      extractoOrdenado = ordenQuini6.reduce((acc, clave) => {
        if (extractoCupon.extracto[clave]) {
          acc[clave] = extractoCupon.extracto[clave];
        }
        return acc;
      }, {});
    } else {
      const numeroPlus = parseInt(
        extractoCupon.extracto["Tradicional"][6].n_numero
      );

      extractoCupon.extracto["Número Plus"] = numeroPlus;

      extractoOrdenado = ordenLotoPlus.reduce((acc, clave) => {
        if (extractoCupon.extracto[clave]) {
          let extractoFiltrado;
          if (clave !== "Número Plus") {
            extractoFiltrado = extractoCupon.extracto[clave].filter(
              (obj) => obj.n_ubica != 7
            );
          } else {
            extractoFiltrado = extractoCupon.extracto[clave];
          }

          acc[clave] = extractoFiltrado;
        }
        return acc;
      }, {});
    }

    setExtracto(extractoOrdenado);
  };

  const guardarFavorita = async (jugadaActual) => {
    const favorita = {
      codigoJuego: jugadaActual.juego,
      numeros: jugadaActual.payload.apuesta,
      numerosExtra: jugadaActual.payload.numeroExtra ?? [],
      programaId: jugadaActual.payload.programa[0],
    };
    let url = "";
    if (props.juego === "quini6") {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_QUINI6_FAVORITA;
    }
    if (props.juego === "lotoplus") {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_LOTOPLUS_FAVORITA;
    }
    try {
      const response = await axios.post(url, favorita, {
        headers: {
          Authorization: `Bearer ${props.estadoPlataforma.userToken}`,
        },
      });

      if (response.status === 200) {
        setJugadaFavorita({
          numeros: favorita.numeros,
          numeroExtra: favorita.numerosExtra,
        });
        setAlert({
          codigo: response.status,
          mensaje: "Se guardó la favorita",
        });
        setTimeout(() => {
          setAlert(undefined);
        }, 2500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getHeadersForInitGame = () => {
    if (isOrgAndNotLoggedIn) return {};
    return {
      Authorization: `Bearer ${props.estadoPlataforma.userToken}`,
    };
  };

  const cuponesPendientes = async () => {
    let url = "";
    if (props.juego === "quini6") {
      url = `${process.env.REACT_APP_URL_BASE_BACKEND}${process.env.REACT_APP_WS_INIT_JUEGO_QUINI6}?codigoJuego=4`;
    } else {
      url = `${process.env.REACT_APP_URL_BASE_BACKEND}${process.env.REACT_APP_WS_INIT_JUEGO_QUINI6}?codigoJuego=3`;
    }

    const response = await axios.get(url, {
      headers: {
        ...getHeadersForInitGame(),
      },
    });
    if (response.status === 200) {
      setCantCuponesPendientes(response.data.cuponesSinSortear);
      console.log(response.data.pozoEstimado);

      window.setPozoActual(response.data.pozoEstimado);
    }
  };

  useEffect(() => {
    let parametros = {
      userToken: props.estadoPlataforma.userToken,
    };
    let url = "";
    if (props.juego === "raspadita_money") {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_JUEGOS_EXTERNOS_HISTORICO +
        parametros.userToken;
    }
    if (props.juego === "quini6") {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_QUINI6_HISTORICO;
      url = url.replace(":codigoJuego", 4);
      url = url.replace(":sorteoProcesado", stateHistorico);
    }
    if (props.juego === "lotoplus") {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_LOTOPLUS_HISTORICO;
      url = url.replace(":codigoJuego", 3);
      url = url.replace(":sorteoProcesado", stateHistorico);
    }

    setUrl(url);
    traerCupones(url);
    traerFavorita();
  }, [stateHistorico, props.estadoPlataforma.logueado]);

  useEffect(() => {
    cuponesPendientes();
  }, [props.estadoPlataforma.logueado]);

  return (
    <>
      {alert && <Alert alert={alert} />}
      <div
        data-toggle="modal"
        data-target={isOrgAndNotLoggedIn ? "#loginModal" : "#lotoidesModal"}
        style={
          ["13", "43", "11", "41", "23", "53", "20", "50", "18", "48"].includes(
            window.getCodigoOrganizacion()
          )
            ? {
                cursor: "pointer",
                color: "white",
                width: "fit-content",
                position: "relative",
              }
            : {
                cursor: "pointer",
                color: "black",
                width: "fit-content",
                position: "relative",
              }
        }
        className="text-center text-small"
        id="pend"
        onClick={() => {
          if (isOrgAndNotLoggedIn) return;
          cargarImgDinamica(props.juego);
          traerCupones(url);
          traerFavorita();
          setStateProximos(true);
          setStateHistorico(false);
          setJugadaActual(undefined);
          setExtracto(undefined);
        }}
      >
        <div
          className="iconify"
          data-icon="uil:ticket"
          data-bs-inline="false"
          data-rotate="90deg"
          data-width="25px"
        />
        {props.estadoPlataforma.logueado && cantCuponesPendientes && (
          <span
            style={{
              bottom: "-2px",
              position: "absolute",
              borderRadius: "50%",
              height: "18px",
              width: "18px",
              left: "-5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={
              ["20", "50"].includes(window.parent.getCodigoOrganizacion())
                ? "badge badge-success"
                : "badge badge-danger"
            }
          >
            <span
              style={{
                verticalAlign: "text-bottom",
                margin: "0",
                padding: "0",
              }}
            >
              {cantCuponesPendientes}
            </span>
          </span>
        )}
      </div>
      {/*---------------------------MODAL JUEGO --------------------------------------------*/}

      <div
        style={{ position: "fixed", top: "6%" }}
        className="modal fade"
        id="lotoidesModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <ModalDialog
          className="modal-dialog modal-style"
          role="document"
          style={{ boxShadow: "0px 0px grey" }}
        >
          <ModalContent className="modal-content">
            {jugadaActual &&
              !extracto &&
              jugadaActual.cupon.totalGanadoSAA > 0 && (
                <HeaderGanador juego={props.juego}>
                  <TituloHeaderGanador>PREMIO</TituloHeaderGanador>
                  <PremioHeaderGanador>
                    <NumberFormat
                      value={jugadaActual.cupon.totalGanadoSAA}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={false}
                      isNumericString={true}
                    />
                  </PremioHeaderGanador>
                  <Ganador />
                </HeaderGanador>
              )}
            <ModalHeader className="modal-header d-flex flex-column align-items-center border-0">
              <LogoJuego src={logoJuego} alt="" />
              {!jugadaActual && stateProximos && (
                <CuponesTitulos>Cupones próximos sorteos</CuponesTitulos>
              )}
              {!jugadaActual && stateHistorico && (
                <CuponesTitulos>Histórico de cupones sorteados</CuponesTitulos>
              )}
              <div
                className="position-absolute"
                style={{ top: "20px", right: "20px" }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  style={{
                    cursor: isHovered ? "pointer" : "default",
                  }}
                >
                  <span aria-hidden="true"> X </span>
                </button>
              </div>
            </ModalHeader>

            {!jugadaActual && !extracto && (
              <CuponesSorteo className="modal-body">
                {!loader && cupones.length === 0 && (
                  <p className="text-center py-4">
                    {stateProximos
                      ? "No hay cupones de sorteos proximos por el momento"
                      : "No hay cupones de sorteos historicos por el momento"}
                  </p>
                )}
                {loader && <Loader />}
                {!loader &&
                  cupones.length > 0 &&
                  cupones?.map((cupon, keyCupon) => (
                    <Animated
                      key={keyCupon}
                      animationIn="fadeIn"
                      animationInDelay={(keyCupon + 1) * 100}
                    >
                      <CuponSorteo
                        juego={props.juego}
                        importeGanado={cupon.importeGanado ?? 0}
                      >
                        <div className="d-flex flex-column justify-content-start align-items-start">
                          <FechaCupon>{formatDate(cupon.fecha)}</FechaCupon>
                          <CantidadApuestas>1 Apuesta</CantidadApuestas>
                        </div>
                        <div className="d-flex flex-row align-items-center">
                          <TotalCupon>
                            <NumberFormat
                              value={cupon.total}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"$"}
                              decimalScale={2}
                              fixedDecimalScale={false}
                              isNumericString={true}
                            />
                          </TotalCupon>
                          <div
                            onClick={() => verDetalle(cupon)}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            style={{
                              cursor: isHovered ? "pointer" : "default",
                            }}
                          >
                            <EyeIcon juego={props.juego} />
                          </div>
                        </div>
                        {cupon.importeGanado && cupon.importeGanado > 0 ? (
                          <Ganador />
                        ) : (
                          ""
                        )}
                      </CuponSorteo>
                    </Animated>
                  ))}
              </CuponesSorteo>
            )}
            {jugadaActual && !extracto && (
              <JugadaActual>
                <Animated animationIn="fadeIn" animationInDelay={100}>
                  <NumeroSorteo>
                    Sorteo N°{" "}
                    {jugadaActual?.extractos?.n_sorte ??
                      jugadaActual.cupon.payload.numeroSorteo[0]}
                  </NumeroSorteo>
                </Animated>
                <Animated animationIn="fadeIn" animationInDelay={200}>
                  <FechaEvento>
                    {formatDate(jugadaActual.cupon.fechaEvento)} Hrs.
                  </FechaEvento>
                </Animated>
                <ContainerNumerosJugados>
                  <Animated animationIn="fadeIn" animationInDelay={300}>
                    <TituloNumerosJugados>Números jugados</TituloNumerosJugados>
                  </Animated>
                  <NumerosJugados>
                    {jugadaActual.cupon.payload.apuesta.map(
                      (numero, keyNumeroJugado) => (
                        <Animated
                          key={keyNumeroJugado}
                          animationIn="fadeIn"
                          animationInDelay={(keyNumeroJugado + 1) * 100}
                        >
                          <NumeroJugado>
                            {props.juego === "quini6" ? (
                              <RedBall size={"100%"} />
                            ) : (
                              <YellowBall size={"100%"} />
                            )}
                            <Numero
                              key={keyNumeroJugado}
                              className={
                                props.juego === "quini6"
                                  ? "text-light"
                                  : "text-dark"
                              }
                            >
                              {numero}
                            </Numero>
                          </NumeroJugado>
                        </Animated>
                      )
                    )}
                  </NumerosJugados>
                </ContainerNumerosJugados>
                <ContainerDetalleJugada>
                  {props.juego !== "quini6" && (
                    <ContainerNumeroExtra>
                      <Animated animationIn="fadeIn" animationInDelay={400}>
                        <TituloNumeroPlus>Número Plus</TituloNumeroPlus>
                      </Animated>
                      <Animated animationIn="fadeIn" animationInDelay={500}>
                        <NumeroExtraBall>
                          <BlackBall size={"100%"} />
                          <NumeroExtra>
                            {parseInt(
                              jugadaActual.cupon.payload.numeroExtra[0]
                            )}
                          </NumeroExtra>
                        </NumeroExtraBall>
                      </Animated>
                    </ContainerNumeroExtra>
                  )}
                  <Animated animationIn="fadeIn" animationInDelay={600}>
                    <ContainerModalidad>
                      <TituloModalidades>Modalidades</TituloModalidades>
                      {props.juego === "quini6" ? (
                        <Modalidades>
                          {MODALITY_VALUE[
                            jugadaActual.cupon.payload.modalidad[0]
                          ]?.join(" - ") ?? ""}
                        </Modalidades>
                      ) : (
                        <Modalidades>Participa en todas</Modalidades>
                      )}
                    </ContainerModalidad>
                  </Animated>
                </ContainerDetalleJugada>
                <ContainerDetalleCupon>
                  <Animated animationIn="fadeIn" animationInDelay={700}>
                    <TituloTotalCupon>
                      Total Cupón{" "}
                      <TotalCuponSAA>
                        <NumberFormat
                          value={jugadaActual.cupon.totalCuponSAA}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={false}
                          isNumericString={true}
                        />
                      </TotalCuponSAA>
                    </TituloTotalCupon>
                  </Animated>
                  <Animated animationIn="fadeIn" animationInDelay={800}>
                    <FechaEmision>
                      Emisión: {formatDate(jugadaActual.cupon.fechaApuestaSAA)}{" "}
                      Hrs.
                    </FechaEmision>
                  </Animated>
                  <Animated animationIn="fadeIn" animationInDelay={900}>
                    <NroControl>
                      Nro. de Control: {jugadaActual.cupon.controlPago}
                    </NroControl>
                  </Animated>
                  <Animated animationIn="fadeIn" animationInDelay={1000}>
                    <NumeroNUC>NUC {jugadaActual.cupon.nuc}</NumeroNUC>
                  </Animated>
                </ContainerDetalleCupon>
              </JugadaActual>
            )}
            {extracto && (
              <ContainerExtracto>
                <TituloExtracto>Extracto</TituloExtracto>
                {Object.keys(extracto).map((keyExtracto) => {
                  const extractoOrdenado =
                    props.juego === "quini6"
                      ? extracto[keyExtracto].sort((a, b) => {
                          return parseInt(a.n_numero) - parseInt(b.n_numero);
                        })
                      : extracto[keyExtracto];

                  return (
                    <ExtractoDetalle key={keyExtracto}>
                      <TituloExtractoDetalle juego={props.juego}>
                        {keyExtracto}
                      </TituloExtractoDetalle>
                      <ContainerNumerosExtracto extracto={extractoOrdenado}>
                        {typeof extractoOrdenado !== "number" &&
                          extractoOrdenado.map(
                            (extractoDetalle, keyExtractoDetalle) => (
                              <NumerosExtracto key={keyExtractoDetalle}>
                                {jugadaActual.cupon.payload.apuesta.includes(
                                  extractoDetalle.n_numero
                                ) ? (
                                  <GreenBall size={"100%"} />
                                ) : (
                                  <WhiteBall size={"100%"} />
                                )}
                                <NumeroExtracto>
                                  {extractoDetalle.n_numero}
                                </NumeroExtracto>
                              </NumerosExtracto>
                            )
                          )}
                        {typeof extractoOrdenado === "number" && (
                          <NumerosExtractoPlus style={{ margin: "0 auto" }}>
                            {jugadaActual.cupon.payload.apuesta.includes(
                              extractoOrdenado
                            ) ? (
                              <GreenBall size={"50"} />
                            ) : (
                              <WhiteBall size={"50"} />
                            )}
                            <NumeroExtracto>{extractoOrdenado}</NumeroExtracto>
                          </NumerosExtractoPlus>
                        )}
                      </ContainerNumerosExtracto>
                    </ExtractoDetalle>
                  );
                })}
              </ContainerExtracto>
            )}
            <ModalFooter>
              {!loader && !jugadaActual && stateProximos && (
                <>
                  <Animated
                    className="mx-auto"
                    animationIn="fadeIn"
                    animationInDelay={500}
                  >
                    <LinkHistorico
                      juego={props.juego}
                      onClick={() => verHistoricos()}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      style={{
                        cursor: isHovered ? "pointer" : "default",
                      }}
                    >
                      Ver histórico
                    </LinkHistorico>
                  </Animated>
                </>
              )}
              {!loader && !jugadaActual && stateHistorico && (
                <>
                  <Animated animationIn="fadeIn" animationInDelay={500}>
                    <ButtonVolver onClick={() => verProximos()} type="button">
                      <span
                        className="iconify"
                        data-icon="tabler:arrow-left"
                        data-width="30px"
                      ></span>
                    </ButtonVolver>
                  </Animated>
                </>
              )}
              {!loader && !extracto && jugadaActual && (
                <>
                  <Animated animationIn="fadeIn" animationInDelay={500}>
                    <ButtonVolver
                      onClick={() => volverACupones()}
                      type="button"
                    >
                      <span
                        className="iconify"
                        data-icon="tabler:arrow-left"
                        data-width="30px"
                      ></span>
                    </ButtonVolver>
                  </Animated>
                  <NavRight>
                    {jugadaActual.extractos && (
                      <Animated animationIn="fadeIn" animationInDelay={600}>
                        <ButtonVerExtracto
                          juego={props.juego}
                          onClick={() => verExtracto(jugadaActual.extractos)}
                          type="button"
                        >
                          Ver Extracto
                        </ButtonVerExtracto>
                      </Animated>
                    )}
                    <Animated animationIn="fadeIn" animationInDelay={700}>
                      <ButtonFavorita
                        onClick={() => guardarFavorita(jugadaActual.cupon)}
                        type="button"
                        disabled={
                          JSON.stringify(jugadaFavorita.numeros.sort()) ===
                          JSON.stringify(
                            jugadaActual.cupon.payload.apuesta.sort()
                          )
                        }
                      >
                        <span
                          className="iconify"
                          data-icon="icon-park-outline:like"
                          data-width="30px"
                        ></span>
                      </ButtonFavorita>
                    </Animated>
                  </NavRight>
                </>
              )}
              {!loader && extracto && (
                <>
                  <ButtonVolver onClick={() => volverAJugada()} type="button">
                    <span
                      className="iconify"
                      data-icon="tabler:arrow-left"
                      data-width="30px"
                    ></span>
                  </ButtonVolver>
                </>
              )}
            </ModalFooter>
          </ModalContent>
        </ModalDialog>
      </div>
    </>
  );
};
export default TraerCuponesPendientesLotoides;
const ModalDialog = styled.div`
  width: 500px;

  @media (max-width: 1024px) {
    width: 420px;
  }

  @media (max-width: 425px) {
    width: 95%;
  }
`;
const ModalContent = styled.div``;
const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 0.4rem;
`;
const LogoJuego = styled.img`
  height: 65px;
  width: 60%;
  object-fit: contain;
  margin: 0;
  padding: 0;
`;
const HeaderGanador = styled.div`
  padding: 1rem 0rem 2rem;
  background: ${(props) =>
    props.juego === "quini6" ? "#E8DF0540" : "#F4A7A84D"};
  border-bottom-left-radius: 25%;
  border-bottom-right-radius: 25%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const TituloHeaderGanador = styled.h2`
  color: black;
  font-size: 22px;
`;
const PremioHeaderGanador = styled.h2`
  color: #08e12b;
`;

const CuponesTitulos = styled.h5`
  font-size: 20px;
  margin: 0;
  margin-top: 1rem;
  font-weight: bolder;
  text-align: center;

  @media (max-width: 425px) {
    font-size: 18px;
  }
`;
const CuponesSorteo = styled.div`
  padding: 0.5rem 0rem 2rem;
  height: 55vh;
  overflow-y: scroll;
`;
const CuponSorteo = styled.div`
  position: relative;
  width: 97%;
  margin: 0 auto;
  background: ${(props) =>
    props.importeGanado !== 0
      ? props.juego === "quini6"
        ? "#E8DF0540"
        : "#F4A7A84D"
      : "#f5f5f5"};
  padding: 0.3rem 1rem
    ${(props) => (props.importeGanado > 0 ? "1.5rem" : "0.3rem")};
  margin-bottom: ${(props) => (props.importeGanado > 0 ? "1.5rem" : "0.5rem")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: ${(props) =>
    props.importeGanado !== 0 ? "25%" : ""};
  border-bottom-right-radius: ${(props) =>
    props.importeGanado !== 0 ? "25%" : ""};
`;
const FechaCupon = styled.p`
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CantidadApuestas = styled.h5`
  font-size: 20px;
  font-weight: bold;
`;
const TotalCupon = styled.h6`
  font-size: 26px;
  line-height: 22px;
  color: #08e12b;
  font-weight: bolder;
  margin: 0;
  margin-right: 1.5rem;

  @media (max-width: 425px) {
    margin-right: 0.7rem;
  }
`;

const LinkHistorico = styled.p`
  text-align: center;

  color: ${(props) => (props.juego === "quini6" ? "#2aa9da" : "#08E12B")};
  text-decoration: underline;
  font-size: 20px;
  margin: 0 auto;
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 0.5rem 0;
`;

const JugadaActual = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const NumeroSorteo = styled.h3`
  font-size: 18px;
`;
const FechaEvento = styled.h4`
  font-size: 18px;
`;
const ContainerNumerosJugados = styled.div`
  padding: 0.3rem 0;
`;
const TituloNumerosJugados = styled.h4`
  text-align: center;
  font-size: 20px;
`;
const NumerosJugados = styled.div`
  display: flex;
  flex-direction: row;
`;
const NumeroJugado = styled.div`
  position: relative;
  margin: 0 0.2rem;
  height: 45px;
  width: 45px;
  @media (max-width: 425px) {
    margin: 0 0.1rem;
    height: 40px;
    width: 40px;
  }
`;
const Numero = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 800;
`;
const ContainerDetalleJugada = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
  padding: 0.3rem 0;
`;
const ContainerNumeroExtra = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TituloNumeroPlus = styled.h4`
  font-size: 19px;
  text-align: center;
`;
const NumeroExtraBall = styled.div`
  position: relative;
  height: 45px;
  width: 45px;

  @media (max-width: 425px) {
    height: 40px;
    width: 40px;
  }
`;
const NumeroExtra = styled.p`
  color: white;
  font-size: 28px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 425px) {
    font-size: 24px;
  }
`;
const ContainerModalidad = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.3rem 0;
  width: 100%;
`;
const TituloModalidades = styled.h4`
  font-size: 19px;
  line-height: 19px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  @media (max-width: 425px) {
    text-align: center;
  }
`;
const Modalidades = styled.span`
  font-size: 17px;
  line-height: 18px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  @media (max-width: 425px) {
    text-align: center;
  }
`;
const ContainerDetalleCupon = styled.div``;
const TituloTotalCupon = styled.h4`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  font-size: 20px;
  font-weight: bold;
`;

const TotalCuponSAA = styled.h4`
  font-size: 22px;
  color: #08e12b;
  margin: 0;
  padding: 0;
  padding-left: 0.7rem;
`;

const FechaEmision = styled.p`
  margin: 0;
  text-align: center;
  font-size: 13px;
  line-height: 15px;
`;
const NroControl = styled.p`
  margin: 0;
  text-align: center;
  font-size: 13px;
  line-height: 15px;
`;
const NumeroNUC = styled.p`
  margin: 0;
  text-align: center;
  padding-bottom: 0rem;
  font-size: 13px;
  line-height: 15px;
`;

const ButtonVolver = styled.button`
  background: #ff0404;
  border: none;
  outline: none;
  color: white;
  padding: 0.15rem 1.5rem;
  border-radius: 35px;
`;

const ButtonVerExtracto = styled.button`
  background: ${(props) => (props.juego === "quini6" ? "#2AA9DA" : "#08e12b")};
  border: none;
  outline: none;
  color: white;
  padding: 0.5rem 0.7rem;
  border-radius: 35px;
  font-size: 18px;

  @media (max-width: 425px) {
    font-size: 15px;
  }
`;
const ButtonFavorita = styled.button`
  border: none;
  outline: none;
  background: ${(props) => (props.disabled ? "#ff0404" : "white")};
  color: ${(props) => (props.disabled ? "#ffffff" : "#ff0404")};
  padding: 0.3rem;
  border-radius: 30px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  transition: 0.5s all;
`;
const NavRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContainerExtracto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 55vh;
  overflow-y: scroll;
`;
const TituloExtracto = styled.h3`
  font-size: 19px;
  font-weight: bold;
`;

const ExtractoDetalle = styled.div`
  width: 90%;

  @media (max-width: 425px) {
    width: 95%;
  }
`;
const TituloExtractoDetalle = styled.h4`
  background: ${(props) =>
    props.juego === "quini6" ? "#E7F8FF" : "#F4A7A84D"};
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0.5rem 1rem;
  box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.25);
  font-size: 22px;

  @media (max-width: 425px) {
    font-size: 20px;
  }
`;
const ContainerNumerosExtracto = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    typeof props.extracto === "number" ? "repeat(1, 1fr)" : "repeat(6, 1fr)"};
  grid-column-gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;

  @media (max-width: 425px) {
    grid-column-gap: 5px;
  }
`;

const NumerosExtracto = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  height: 45px;
  width: 45px;

  @media (max-width: 425px) {
    height: 40px;
    width: 40px;
  }
`;
const NumerosExtractoPlus = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
`;
const NumeroExtracto = styled.p`
  font-size: 23px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 425px) {
    font-size: 18px;
  }
`;
