import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import "jquery-confirm";
import CambiarClave from "../cambiarClave";
import styled from "styled-components";
import { estilosProv } from "../configProvincias";
import { DivGeneral } from "./consultaBilletera";
import { checkOrgAndNotLoggedIn } from "../utils/generalUtils";

const MisDatosPersonales = (props) => {
  //---------------HOOKS DE ESTADO ------------------------------
  const [error, seterror] = useState(false);
  const [email, setemail] = useState("");
  const [nombre, setnombre] = useState("");
  const [cbu, setCbu] = useState("");
  const [apellido, setapellido] = useState("");
  const [documento, setdocumento] = useState("");
  const [cuil, setcuil] = useState("");
  const [direccion, setdireccion] = useState("");
  const [fechaNacimiento, setfechaNacimiento] = useState("");
  const [localidad, setlocalidad] = useState("");
  const [nroTelefono, setnroTelefono] = useState("");
  const [tipoDocumento, settipoDocumento] = useState("");
  const [genero, setgenero] = useState("");
  const [agencia, setagencia] = useState({});
  const [provincia, setprovincia] = useState("");
  const [recibeMsjEmail, setrecibeMsjEmail] = useState("");
  const [cambioExitoso, setcambioExitoso] = useState(false);
  const [listaLocalidades, setlistaLocalidades] = useState([]);
  const [cambioAgencia, setcambioAgencia] = useState({ agencia: {} });
  const [permiteCambioAg, setpermiteCambioAg] = useState(true);
  const [permiteCancelarCambioAg, setpermiteCancelarCambioAg] = useState(null);
  const [listaSubAgencias, setlistaSubAgencias] = useState(undefined);
  const [agencias, setagencias] = useState(undefined);
  const [includeSubAg, setincludeSubAg] = useState(undefined);
  const [errores, seterrores] = useState(undefined);
  const [hashAgencias, sethashAgencias] = useState(undefined);
  const codOrg = process.env.REACT_APP_COD_ORGANIZACION;
  const fontColor = estilosProv[codOrg].fontColor;
  const fondo = estilosProv[codOrg].fondo;

  const isOrgAndNotLoggedIn = checkOrgAndNotLoggedIn(
    ["53", "23"],
    props.estadoPlataforma.logueado
  );
  //---------------HOOKS DE EFFECTO ------------------------------

  useEffect(() => {
    if (agencias !== undefined) {
      seleccionaTuAgencia();
    }
  }, [agencias]);

  useEffect(() => {
    window.scrollTo(0, 0);

    props.logCurrentPage("usuario");
    props.actuVersionSiCorresponde(props);
    traerMisDatosPersonales();
  }, []);

  useEffect(() => {
    if (
      ["53", "23"].includes(process.env.REACT_APP_COD_ORGANIZACION) &&
      props.estadoPlataforma.logueado
    ) {
      traerMisDatosPersonales();
    }
  }, [props.estadoPlataforma.logueado]);

  //----------------FUNCIONES --------------------------------------

  /**
   *
   * @returns una funcion que tomado dos elementos a y b los ordena
   */
  const ordenarAgencias = () => {
    return function (a, b) {
      var aLocalidad = a.localidad.toUpperCase();
      var bLocalidad = b.localidad.toUpperCase();
      var aDescripcion = a.descripcion.toUpperCase();
      var bDescripcion = b.descripcion.toUpperCase();

      if (aLocalidad === bLocalidad) {
        if (!isNaN(a.descripcion)) aDescripcion = Number(a.descripcion);
        if (!isNaN(b.descripcion)) bDescripcion = Number(b.descripcion);
        return aDescripcion < bDescripcion
          ? -1
          : aDescripcion > bDescripcion
          ? 1
          : 0;
      } else {
        return aLocalidad < bLocalidad ? -1 : 1;
      }
    };
  };

  /**
   * Se realizá un peticion fetch (POST) con los siguientes parametros: codigoOrganizacion y recibeMsjEmail.
   * En caso que la peticion sea correcta se mostrará una notificacion confirmando que se activó o desactivó
   * la notificacion por mail.
   * @param {boolean} activarla
   */
  const grabarCambioNotificacion = (activarla) => {
    let parametros = {
      codigoOrganizacion: process.env.REACT_APP_COD_ORGANIZACION_FETCH,
      recibeMsjEmail: activarla,
    };

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_CAMBIAR_DATOS_PERSONALES;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
      body: JSON.stringify(parametros),
    })
      .then((respPromise) => {
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          props.cerrarSesion();
          props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          traerMisDatosPersonales();
        } else if (json.status === "error") {
          let notificacion = "";
          if (activarla) notificacion = "Activar notificación correo";
          else notificacion = "Desactivar notificación correo";
          props.mensajeErrorWS(notificacion, json.errores);
        }
      })
      .catch((error) => {
        props.mensajeErrorGeneral();
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
      });
  };

  /**
   * Ejecuta un modal con Jquery en caso de aceptar se ejecutara la funcion @grabarCambioNotificacion con un argumento en true
   */
  const activarNotificacionCorreo = () => {
    $.confirm({
      title: '<b className="op-text">Notificaciones</b>',
      backgroundDismiss: true,
      columnClass: "medium",
      animation: "zoom",
      closeIcon: false,
      closeAnimation: "scale",
      content: `<h5 className="text-center mt-4 mb-2">
						¿Desea activar las notificaciones por correo?  
					 </h5>`,
      buttons: {
        No: {
          text: "Cancelar",
          action: function () {
            var check = document.getElementById("check");
            check.checked = false;
          },
        },
        Si: {
          text: "Aceptar",
          action: function () {
            grabarCambioNotificacion(true);
          },
        },
      },
    });
  };

  /**
   * Ejecuta un modal con Jquery en caso de aceptar se ejecutara la funcion @grabarCambioNotificacion con un argumento en false
   */
  const desactivarNotificacionCorreo = () => {
    $.confirm({
      title: '<b className="op-text">Notificaciones</b>',
      backgroundDismiss: true,
      columnClass: "medium",
      animation: "zoom",
      closeIcon: false,
      closeAnimation: "scale",
      content: `<h5 className="text-center mt-4 mb-2">
						¿Desea desactivar las notificaciones por correo?  
					 </h5>`,
      buttons: {
        No: {
          text: "Cancelar",
          action: function () {
            var check = document.getElementById("check");
            check.checked = true;
          },
        },
        Si: {
          text: "Aceptar",
          action: function () {
            grabarCambioNotificacion(false);
          },
        },
      },
    });
  };

  /**
   * toma el Check y según como se encuentre este se ejecutara @activarNotificacionCorreo o @desactivarNotificacionCorreo
   * @param {*} e
   */
  const handleSwitchNoti = (e) => {
    if (e.target.checked) {
      activarNotificacionCorreo();
    } else {
      desactivarNotificacionCorreo();
    }
  };

  /**
   * A partir de una agencia que la obtenemos por el argumento, obtenemos el codigo de la agencia.
   * Posteriormente se realiza una peticion fetch (GET) donde en el caso de que la peticion se realice con éxito
   * nos devolvera una lista de subagencias de la agencia mencionada. Esas subagencias se setearan en un estado mediante
   * @setlistaSubAgencias
   * @param {*} agencia
   */
  const handleSubAgencia = (agencia) => {
    const encodedValue = process.env.REACT_APP_COD_ORGANIZACION_FETCH;
    let codigoAgencia = agencia.codigo;

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_LISTADO_SUBAGENCIAS +
      "?codigoOrganizacion=" +
      encodedValue +
      "&codigoAgencia=" +
      codigoAgencia;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((respPromise) => {
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          props.cerrarSesion();
          props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          $("#subAgModal").modal("toggle");
          setlistaSubAgencias(json);
        } else if (json.status === "error") {
          props.mensajeErrorWS("Registración", json.errores);
          props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
      })
      .catch((error) => {
        props.mensajeErrorGeneral();
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
        props.history.push("/");
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
      });
  };

  /**
   * Se realiza una peticion fetch (GET) donde en el caso de que la peticion se realice con éxito
   * nos devolvera una lista de agencias. Esas subagencias se setearan en un estado mediante
   * @setagencias y tambien @setincludeSubAg
   * @param {*} agencia
   */
  const traerAgencias = () => {
    const encodedValue = process.env.REACT_APP_COD_ORGANIZACION_FETCH;

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_AGENCIAS +
      "?codigoOrganizacion=" +
      encodedValue;

    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;

        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          props.cerrarSesion();
          props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setagencias(json.agencias);
          setincludeSubAg(json.includeSubAg);
        } else if (json.status === "error") {
          props.mensajeErrorWS("Mis datos personales", json.errores);
          props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
        return json.status;
      })
      .catch((error) => {
        props.mensajeErrorGeneral();
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
        props.history.push("/");
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
      });
  };

  /**
   * A partir de una agencia que la obtenemos por el argumento, obtenemos el codigo de la agencia.
   * Posteriormente se realiza una peticion fetch (POST) con los siguientes parametros en el body: codigoOrganizacion, agencia y cancelar.
   * Donde en el caso de que la peticion se realice con éxito se cambiara la agencia actual por la que se encuentra en el argumento de la funcion
   * @param {*} agencia
   */
  const grabarCambioAgencia = (agencia) => {
    let parametros = {
      codigoOrganizacion: process.env.REACT_APP_COD_ORGANIZACION_FETCH,
      agencia: agencia.codigo,
      cancelar: false,
    };

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_CAMBIAR_AGENCIA;

    let cerrarSesion = false;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
      body: JSON.stringify(parametros),
    })
      .then((respPromise) => {
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          traerMisDatosPersonales();
        } else if (json.status === "error") {
          if (cerrarSesion) {
            props.mensajeErrorWS("Cambiar agencia", json.errores, cerrarSesion);
          } else {
            seterrores(json.errores);
            setcambioExitoso(false);
            props.mensajeErrorWS("Cambiar agencia", json.errores);
          }
        }
      })
      .catch((error) => {
        props.mensajeErrorGeneral();
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
      });
  };

  const cancelarAgencia = () => {
    let parametros = {
      codigoOrganizacion: process.env.REACT_APP_COD_ORGANIZACION_FETCH,
      agencia: "0_0",
      cancelar: true,
    };

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_CAMBIAR_AGENCIA;

    let cerrarSesion = false;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
      body: JSON.stringify(parametros),
    })
      .then((respPromise) => {
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setcambioAgencia({
            agencia: {},
          });

          traerMisDatosPersonales();
        } else if (json.status === "error") {
          if (cerrarSesion) {
            props.mensajeErrorWS(
              "Cancelar cambio agencia",
              json.errores,
              cerrarSesion
            );
          } else {
            seterrores(json.errores);
            setcambioExitoso(false);

            props.mensajeErrorWS("Cancelar cambio agencia", json.errores);
          }
        }
      })
      .catch((error) => {
        props.mensajeErrorGeneral();
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
      });
  };

  const handleAgencia = (agencia) => {
    //REACT_APP_WS_CAMBIAR_AGENCIA
    $("#agenciaModal").modal("hide");
    grabarCambioAgencia(agencia);
  };

  const cancelarCambioAgencia = () => {
    $.confirm({
      title: '<b className="op-text">Cambio de agencia</b>',
      backgroundDismiss: true,
      columnClass: "medium",
      animation: "zoom",
      closeIcon: true,
      closeAnimation: "scale",
      content: `<h5 className="text-center mt-4 mb-2">
						¿Desea cancelar el cambio de agencia en curso?  
					 </h5>`,
      buttons: {
        No: {
          text: "Cancelar",
          action: function () {},
        },
        Si: {
          text: "Aceptar",
          action: function () {
            cancelarAgencia();
          },
        },
      },
    });
  };

  const seleccionaTuAgencia = () => {
    // Armar listas de Objetos de localidades con sus agencias
    let agenciasLocal = agencias;
    let agenciasOrd = agenciasLocal.sort(ordenarAgencias());

    let aHashAgencias = new Object();
    let aListaLocalidades = [];

    let i = 0;
    for (i = 0; i < agenciasOrd.length; ++i) {
      if (aHashAgencias[agenciasOrd[i].localidad] === undefined) {
        aHashAgencias[agenciasOrd[i].localidad] = new Array();
        aListaLocalidades.push({ localidad: agenciasOrd[i].localidad });
      }
      let arrayAgencias = aHashAgencias[agenciasOrd[i].localidad];
      arrayAgencias.push(agenciasOrd[i]);
    }

    setlistaLocalidades(aListaLocalidades);
    sethashAgencias(aHashAgencias);
  };

  const traerMisDatosPersonales = () => {
    if (isOrgAndNotLoggedIn) return;

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_MIS_DATOS_PERSONALES;

    let cerrarSesion = false;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setemail(json.email);
          setnombre(json.nombre);
          setCbu(json.cbu);
          setapellido(json.apellido);
          setdocumento(json.documento);
          setcuil(json.cuil);
          setdireccion(json.direccion);
          setfechaNacimiento(json.fechaNacimiento);
          setlocalidad(json.localidad);
          setnroTelefono(json.nroTelefono);
          settipoDocumento(json.tipoDocumento);
          setgenero(json.genero);
          setagencia(json.agencia);
          setprovincia(json.provincia);
          setrecibeMsjEmail(json.recibeMsjEmail);
          setcambioAgencia(json.cambioAgencia);
          setpermiteCancelarCambioAg(json.permiteCancelarCambioAg);

          if (json.permiteCambioAg !== undefined) {
            setpermiteCambioAg(json.permiteCambioAg);
          }
        } else if (json.status === "error") {
          seterror(true);

          if (cerrarSesion) {
            props.mensajeErrorWS(
              "Consulta datos personales",
              json.errores,
              cerrarSesion
            );
          } else {
            props.mensajeErrorWS("Consulta datos personales", json.errores);
          }
        }
      })
      .catch((error) => {
        seterror(true);
        props.mensajeErrorGeneral();
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
      });
  };

  return (
    <DivGeneral hidden={props.estadoPlataforma.estado !== "PLATAFORMA"}>
      <Animated animationIn="slideInLeft" isVisible={true}>
        <div className="container" style={{marginBottom: "100px"}}>
          {error === false && (
            <RowDatosPersonales className="row justify-content-md-center">
              <DivColGeneral className="col-sm-6">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <DivTituloDatosPers
                        fondo={fondo}
                        fontColor={fontColor}
                        codOrg={codOrg}
                      >
                        <h5>Datos personales</h5>
                      </DivTituloDatosPers>
                    </div>
                    <div className="col-12">
                      <span className="op-text header-datos">
                        Nombre y apellido
                      </span>
                      <br />
                      <span>{nombre ? `${nombre} ${apellido}` : "-"}</span>
                      <hr className="mt-2 mb-2" />
                    </div>
                    {["53", "23"].includes(codOrg) && (
                      <div className="col-12">
                        <span className="op-text header-datos">CBU</span>
                        <br />
                        <span>{cbu ?? "-"}</span>
                        <hr className="mt-2 mb-2" />
                      </div>
                    )}
                    <div className="col-12">
                      <span className="op-text header-datos">Nacimiento</span>
                      <br />
                      <span>{fechaNacimiento ?? "-"}</span>
                      <hr className="mt-2 mb-2" />

                      <span className="op-text header-datos">Documento </span>
                      <br />
                      <span>{documento}</span>
                      <hr className="mt-2 mb-2" />

                      {cuil !== null && (
                        <>
                          <span className="op-text header-datos">Cuil</span>
                          <br />
                          <span>{cuil}</span>
                          <hr className="mt-2 mb-2" />
                        </>
                      )}
                    </div>
                    <div className="col-12">
                      <span className="op-text header-datos">
                        Tu agencia amiga
                      </span>
                      <br />
                      <span>
                        {agencia.descripcion} - {agencia.direccion}
                      </span>
                      <br />
                      <span className="op-text header-datos">
                        {agencia.localidad}{" "}
                      </span>
                    </div>
                    <div className="col-12">
                      {cambioAgencia != null &&
                        cambioAgencia.agencia != null &&
                        cambioAgencia.agencia.codigo != null && (
                          <div className="text-center mt-3">
                            <p className="text-small">
                              <b>
                                El {cambioAgencia.fechaProgramada} se efectuará
                                el cambio a la agencia abajo indicada
                              </b>
                            </p>
                            <div className="row border mt-2 w-90 mx-auto">
                              <div className="ml-4 mr-4 sm-10">
                                <span>
                                  Agencia {cambioAgencia.agencia.descripcion}
                                </span>{" "}
                                - <span>{cambioAgencia.agencia.direccion}</span>
                                <br></br>
                                <span>{cambioAgencia.agencia.localidad}</span>
                              </div>

                              {cambioAgencia != null &&
                                cambioAgencia.permiteCancelarCambioAg ===
                                  true && (
                                  <div className="mx-auto pt-2 pb-2">
                                    <button
                                      type="button"
                                      className="btn btn-outline-danger"
                                      data-toggle="modal"
                                      onClick={() => {
                                        cancelarCambioAgencia();
                                      }}
                                    >
                                      Cancelar cambio en curso
                                    </button>
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="col-12">
                      {(permiteCambioAg === true ||
                        (cambioAgencia != null &&
                          cambioAgencia.agencia != null &&
                          cambioAgencia.agencia.codigo == null)) && (
                        <BotonModales
                          fondo={fondo}
                          fontColor={fontColor}
                          codOrg={codOrg}
                          type="button"
                          className="btn btn-cambiar-ag"
                          data-toggle="modal"
                          data-target="#agenciaModal"
                          onClick={traerAgencias}
                        >
                          Modificar agencia
                          <span
                            style={{ float: "right" }}
                            className="iconify"
                            data-icon="dashicons:arrow-right-alt2"
                            data-inline="false"
                            data-width="28px"
                          ></span>
                        </BotonModales>
                      )}
                    </div>
                  </div>
                </div>
              </DivColGeneral>

              <DivColGeneral className="col-sm-6">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <DivTituloDatosPers
                        fondo={fondo}
                        fontColor={fontColor}
                        codOrg={codOrg}
                      >
                        <h5>Datos de contacto</h5>
                      </DivTituloDatosPers>
                    </div>
                    <div className="col-6">
                      <span className="op-text header-datos">Provincia</span>
                      <br />
                      <span>{provincia}</span>
                    </div>
                    <div className="col-6">
                      <span className="op-text header-datos">Localidad</span>
                      <br />
                      <span>{localidad}</span>
                    </div>
                  </div>
                  <hr className="mt-2 mb-2" />
                </div>
                <div className="col-12">
                  <span className="op-text header-datos">Dirección</span>
                  <br />
                  <span>{direccion ?? "-"}</span>
                  <hr className="mt-2 mb-2" />
                </div>
                <div className="col-12">
                  <span className="op-text header-datos">Teléfono</span>
                  <br />
                  <span>{nroTelefono ?? "-"}</span>
                  <hr className="mt-2 mb-2" />
                </div>
                <div className="col-12">
                  <span className="op-text header-datos">Correo</span>
                  <br />
                  <span>{email}</span>
                  <hr className="mt-2 mb-2" />
                </div>
                <div className="col-12">
                  <span className="op-text header-datos">
                    Notificaciones por email
                  </span>
                  <br />

                  <div className="mt-1 mb-1 redondo">
                    <input
                      type="checkbox"
                      id="check"
                      checked={recibeMsjEmail}
                      readOnly={true}
                      onClick={handleSwitchNoti}
                    />
                    <label
                      htmlFor="check"
                      style={{ top: "18%", left: "-1%" }}
                    />
                    <span className="ml-2">Activar</span>
                  </div>
                </div>
                <div className="col-12">
                  <BotonModales
                    fondo={fondo}
                    fontColor={fontColor}
                    codOrg={codOrg}
                    type="button"
                    onClick={() => {
                      setcambioExitoso(false);
                      $("#clave").val("");
                      $("#nuevaClave").val("");
                      $("#confirmaNuevaClave").val("");
                    }}
                    className="btn btn-cambiar-ag mt-3"
                    data-toggle="modal"
                    data-target="#passModal"
                  >
                    Modificar contraseña
                    <span
                      style={{ float: "right" }}
                      className="iconify op-text"
                      data-icon="dashicons:arrow-right-alt2"
                      data-inline="false"
                      data-width="28px"
                    ></span>
                  </BotonModales>
                </div>
              </DivColGeneral>
            </RowDatosPersonales>
          )}

          {error === true && (
            <div>
              <div style={{ textAlign: "center" }}>
                <br></br>
                <p>
                  La consulta de datos personales no se encuentra disponible.
                </p>
                <p>Favor reintente más tarde.</p>
              </div>
            </div>
          )}
        </div>
      </Animated>

      {/*---------------------------------------MODAL CHANGE AGECNIA-----------------------------------------------------*/}

      <div
        className="modal fade"
        id="agenciaModal"
        role="dialog"
        aria-labelledby="agenciaModalLabel"
        aria-hidden="true"
        style={
          navigator.vendor.toLocaleLowerCase().includes("apple")
            ? { overflow: "auto", top: "14vh", height: "72vh" }
            : { overflow: "auto" }
        }
      >
        <div className="modal-dialog w-100 m-0" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{
                zIndex: "3",
                backgroundColor: "white",
                top: "0",
                width: "100%",
              }}
            >
              <h6 className="modal-title op-text" id="agenciaModalLabel">
                Seleccione <span className="m-bold">localidad</span> <br /> y{" "}
                <span className="m-bold">agencia</span> más cercana
              </h6>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body text-center mb-2">
              {listaLocalidades.map((value, index) => (
                <div>
                  <div
                    className="border p-1 mb-1 text-left"
                    data-toggle="collapse"
                    href={"#opt" + index}
                    role="button"
                    aria-expanded="false"
                    aria-controls={"#opt" + index}
                  >
                    <span style={{ fontSize: "12px" }} className="op-text">
                      Localidad
                    </span>
                    <br />
                    <span className="m-bold">{value.localidad}</span>
                  </div>

                  {!includeSubAg &&
                    hashAgencias[value.localidad].map((agencia) => (
                      <div className="collapse" id={"opt" + index}>
                        <div
                          onClick={() => {
                            handleAgencia(agencia);
                          }}
                          data-dismiss="modal"
                          className="text-left p-2 mb-1 border-bottom"
                        >
                          <span className="m-bold">
                            Agencia n° {agencia.descripcion}
                          </span>
                          <br />
                          <span
                            style={{ fontSize: "12px" }}
                            className="op-text"
                          >
                            {agencia.direccion}
                          </span>
                          <span
                            style={{
                              display: "flex",
                              float: "right",
                              marginTop: "-3%",
                            }}
                            className="iconify"
                            data-icon="dashicons:arrow-right-alt2"
                            data-inline="false"
                            data-width="20px"
                          ></span>
                        </div>
                      </div>
                    ))}

                  {includeSubAg &&
                    hashAgencias[value.localidad].map((agencia) => (
                      <div className="collapse" id={"opt" + index}>
                        <div
                          onClick={() => {
                            handleSubAgencia(agencia);
                          }}
                          className="text-left p-2 mb-1 border-bottom"
                        >
                          <span className="m-bold">
                            Agencia n° {agencia.descripcion}
                          </span>
                          <br />
                          <span
                            style={{ fontSize: "12px" }}
                            className="op-text"
                          >
                            {agencia.direccion}
                          </span>
                          <span
                            style={{
                              display: "flex",
                              float: "right",
                              marginTop: "-3%",
                            }}
                            className="iconify"
                            data-icon="dashicons:arrow-right-alt2"
                            data-inline="false"
                            data-width="20px"
                          ></span>
                        </div>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/*---------------------------------------MODAL SUB AGECNIA-----------------------------------------------------*/}

      <div
        style={
          navigator.vendor.toLocaleLowerCase().includes("apple")
            ? { position: "fixed", top: "14%", height: "72vh" }
            : { overflow: "auto", position: "fixed" }
        }
        className="modal fade "
        id="subAgModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="subAgModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog subAgModal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <span
                className="modal-title"
                style={{ fontSize: "16px" }}
                id="subAgModalLabel"
              >
                <b>Sub Agencias</b>
              </span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {listaSubAgencias !== undefined &&
                listaSubAgencias.agencias.map((subAgencia) => (
                  <div
                    onClick={() => {
                      handleAgencia(subAgencia);
                    }}
                    data-dismiss="modal"
                    className="p-1 mb-1 border"
                  >
                    <span>Agencia {subAgencia.descripcion}</span> -{" "}
                    <span>{subAgencia.direccion}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {/*---------------------------------------MODAL CHANGE PASS-----------------------------------------------------*/}

      <div
        style={{ position: "fixed", top: "8%" }}
        className="modal fade pb-5"
        id="passModal"
        role="dialog"
        aria-labelledby="passModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="passModalLabel">
                Modificar contraseña
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body text-center pt-0">
              <CambiarClave
                handleRemember={props.handleRemember}
                cambioExitoso={cambioExitoso}
                cambioClaveExitoso={setcambioExitoso}
                mensajeErrorGeneral={props.mensajeErrorGeneral}
                mensajeErrorWS={props.mensajeErrorWS}
                estadoPlataforma={props.estadoPlataforma}
              />
            </div>
          </div>
        </div>
      </div>
    </DivGeneral>
  );
};

export default withRouter(MisDatosPersonales);

const DivTituloDatosPers = styled.div`
  font-family: "Mont-Bold";
  background-color: ${(props) => props.fondo};
  color: ${(props) =>
    ["20", "50"].includes(props.codOrg) ? "black" : props.fontColor};
  padding: 0.5rem;
`; //color: ${(props) => props.fontColor}; --original

const BotonModales = styled.button`
  background-color: ${(props) => props.fondo};
  color: ${(props) =>
    ["20", "50"].includes(props.codOrg) ? "black" : props.fontColor};
  margin-left: 0%;
`; //color: ${(props) => props.fontColor}; --original

const DivColGeneral = styled.div`
  margin-top: 2vh;
`;

const RowDatosPersonales = styled.div`
  margin-left: 0%;
`;
