import React from "react";
import { Form, FormGroup, Input } from "reactstrap";
import $ from "jquery";
import "jquery-confirm";
import { Animated } from "react-animated-css";
import Recaptcha from "react-recaptcha";
import styled from "styled-components";
import ButtonSalta from "./ButtonSalta";
import { withRouter } from "react-router-dom";
import OlvideClave from "../../olvideClave";

const formGroupStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "5px",
};

const inputStyles = {
  borderRadius: "6px",
  background: "#fff",
};

const eyeStyles = {
  position: "absolute",
  top: "8px",
  right: "10px",
};

class ModalLoginSalta extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickBorrarCampos = this.handleClickBorrarCampos.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.loguearse = this.loguearse.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.onExpiroCaptcha = this.onExpiroCaptcha.bind(this);
    this.irARegistracion = this.irARegistracion.bind(this);
    this.handlePassView = this.handlePassView.bind(this);
    this.generoNuevaClave = this.generoNuevaClave.bind(this);
    this.uncheck = this.uncheck.bind(this);
    this.uncheckOnError = this.uncheckOnError.bind(this);

    this.state = {
      value: "",
      envioGeneracion: false,
      nroDocu: localStorage.usrname,
      clave: localStorage.pass,
      checked: false,
      pass: false,
      cargando: "INICIO",
      nombre: "",
      apellido: "",
      userToken: "",
      saldo: 0,
      requiereCambio: undefined,
      errores: [],
      logueado: false,
      captchaVerificado: false,
      errors: {
        nroDocu: "",
        clave: "",
        captcha: "",
      },
    };
  }

  uncheck() {
    if ($("#remember_me").is(":checked")) {
    } else {
      this.setState({ nroDocu: "", clave: "" });
      $("#pasLabel").removeClass("hide");
    }

    this.setState({ checked: !this.state.checked });
  }

  uncheckOnError() {
    this.setState({ clave: "", checked: false });
    $("#pasLabel").removeClass("hide");
  }

  generoNuevaClave(enviada) {
    this.setState({ envioGeneracion: enviada });
  }

  handlePassView() {
    let x = $("#clave");
    if (x.hasClass("text-pass")) {
      x.removeClass("text-pass");
    } else {
      /* LOGO_SPLASH_13 */
      x.addClass("text-pass");
    }

    this.setState({ pass: !this.state.pass });
  }

  irARegistracion() {
    this.props.history.push("/registrarse");
    $("#loginModal").modal("hide");
  }

  onExpiroCaptcha() {
    this.setState({ captchaVerificado: false });
  }

  verifyCallback() {
    this.setState({ errors: { captcha: "" } });
    this.setState({ captchaVerificado: true });
  }

  onLoadRecaptcha() {
    if (this.recaptchaInstance) {
      this.recaptchaInstance.reset();
      this.setState({ captchaVerificado: false });
    }
  }

  componentDidMount() {
    if (localStorage.chkbx === "on") {
      $("#pasLabel").addClass("hide");
    }

    this.props.actuVersionSiCorresponde(this.props);

    if (this.recaptchaInstance) {
      this.recaptchaInstance.reset();
      this.setState({ captchaVerificado: false });
    }
  }

  handleClickBorrarCampos = (event) => {
    $("#nroDocu").val("");
    $("#clave").val("");

    let limpiarErrors = {
      nroDocu: "",
      clave: "",
      captcha: "",
    };

    this.setState({ errors: limpiarErrors });
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "nroDocu":
        errors.nroDocu =
          value.length === 0 ? "Ingresar nro. de documento o correo " : "";
        break;
      case "clave":
        if (value === "") {
          $("#pasLabel").removeClass("hide");
        } else {
          $("#pasLabel").addClass("hide");
        }

        errors.clave =
          value.length < 8
            ? "La clave debe contener al menos 8 caracteres"
            : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value }, () => {});
  };

  validateForm() {
    let valid = true;

    let errors = this.state.errors;

    if ($("#nroDocu").val() === "") {
      errors.nroDocu = "Ingresar nro. de documento o correo";
      valid = false;
    }

    if ($("#clave").val() === "") {
      errors.clave = "Ingresar clave";
      valid = false;
    }

    if (errors.nroDocu.length > 0) $("#nroDocu").focus();
    else if (errors.clave.length > 0) $("#clave").focus();

    if (
      this.state.captchaVerificado === false &&
      process.env.REACT_APP_ACTIVAR_CAPTCHA_LOGIN === "si"
    ) {
      errors.captcha = "Debes indicar que no eres un robot";
      if (valid) {
        valid = false;
        $("#IngresoCaptcha").focus();
      }
    }

    return valid;
  }

  loguearse() {
    this.setState({ cargando: "EJECUTANDO" });
    var formBody = [];

    var userName = encodeURIComponent("username");
    var valueUserName = encodeURIComponent($("#nroDocu").val().trim());
    formBody.push(userName + "=" + valueUserName);

    var clave = encodeURIComponent("password");
    var valueClave = encodeURIComponent($("#clave").val().trim());
    formBody.push(clave + "=" + valueClave);

    var codigoOrganizacion = encodeURIComponent("codigoOrganizacion");
    var valueCodigoOrganizacion = encodeURIComponent(
      process.env.REACT_APP_COD_ORGANIZACION_FETCH
    );
    formBody.push(codigoOrganizacion + "=" + valueCodigoOrganizacion);

    formBody = formBody.join("&");

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND + process.env.REACT_APP_WS_LOGIN;

    let statusCode = "";

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          this.setState({
            errores: [],
            logueado: true,
            nombre: json.nombre,
            apellido: json.apellido,
            saldo: json.saldo,
            userToken: json.key,
          });
          sessionStorage.setItem(
            "Usuario",
            JSON.stringify({
              errores: [],
              logueado: true,
              nombre: json.nombre,
              apellido: json.apellido,
              saldo: json.saldo,
              userToken: json.key,
              email: json.email,
            })
          );
          this.setState({ cargando: "FIN" });

          this.props.login(
            json.nombre,
            json.apellido,
            json.key,
            json.autoExclusionHasta,
            json.acceptedTyC,
            json.nps,
            json.requiereCambioContraseña,
            json.email
          );
          sessionStorage.setItem(
            "login",
            JSON.stringify({
              nombre: json.nombre,
              apellido: json.apellido,
              key: json.key,
              autoExclusionHasta: json.autoExclusionHasta,
              acceptedTyC: json.acceptedTyC,
              nps: json.nps,
              requiereCambioContraseña: json.requiereCambioContraseña,
              email: json.email,
            })
          );
          this.props.setSaldo(json.saldo);

          this.props.setCambio(json.requiereCambioContraseña);

          $("#loginModal").modal("hide");
          // this.props.setCambio(true);

          if (localStorage.chkbx === "on") {
            // save username and password
            localStorage.usrname = this.state.nroDocu;
            localStorage.pass = this.state.clave;
          }

          if (
            this.props.notificacionMensajes != null &&
            this.props.notificacionMensajes.ejecutar != null
          )
            this.props.notificacionMensajes.ejecutar();

          // if (this.props.estadoPlataforma.operRequirioLogin === "LOGIN")
          //   this.props.history.push("/home");
          // else if (
          //   this.props.estadoPlataforma.operRequirioLogin
          //     .toUpperCase()
          //     .startsWith("HTTP")
          // ) {
          //   this.props.history.push("/home");
          //   var str = "";
          //   if (process.env.REACT_APP_CODIGO_JUEGO === "0")
          //     str =
          //       process.env.REACT_APP_URL_BASE_FRONTEND +
          //       process.env.REACT_APP_URL_JUEGO_TOMBOLA;
          //   else if (process.env.REACT_APP_CODIGO_JUEGO === "1")
          //     str =
          //       process.env.REACT_APP_URL_BASE_FRONTEND +
          //       process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS;
          //   if (str.slice(-1) === "/") str = str.slice(0, -1);

          //   this.props.arrancarJuego(
          //     this.props.estadoPlataforma.operRequirioLogin
          //   );
          // } else {
          //   this.props.history.push("/home");
          // }
        } else {
          this.uncheckOnError();
          this.setState({ cargando: "FIN" });
          this.setState({
            errores: json.errores,
            logueado: false,
            nombre: "",
            apellido: "",
            saldo: 0,
            userToken: "",
          });
          this.props.mensajeErrorWS("Ingreso al sistema", json.errores);
        }
      })
      .catch((error) => {
        this.props.mensajeErrorGeneral();
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
        this.setState({ cargando: "FIN" });
        this.uncheckOnError();
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.loguearse();
    } else {
      this.setState({});
    }
  };

  render() {
    const jsxCaptcha = (
      <div>
        {process.env.REACT_APP_ACTIVAR_CAPTCHA_LOGIN === "si" && (
          <div style={{ transform: "scale(0.96)", transformOrigin: "0 0" }}>
            <Recaptcha
              ref={(e) => (this.recaptchaInstance = e)}
              sitekey={process.env.REACT_APP_SITE_KEY_CAPTCHA}
              render="explicit"
              hl={"es"}
              size="normal"
              onloadCallback={this.onLoadRecaptcha}
              verifyCallback={this.verifyCallback}
              expiredCallback={this.onExpiroCaptcha}
            />
            <br></br>
            <span
              className="text-danger text-small"
              style={{ position: "relative", top: "-15px" }}
            >
              {this.state.errors.captcha}
            </span>
          </div>
        )}
      </div>
    );

    const position = this.props.positionBtnLogin;

    return (
      <div
        className="modal fade"
        // style={{ position: "fixed", top: "10%" }}
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.5)", // Semi-transparent backdrop
          zIndex: 9,
        }}
        //   style={{
        //   top: position ? position.top - 40 + "px" : "20%",
        //   left: position ? window.innerWidth - position.left + "px" : "0%",
        //   position: "fixed",
        // }}
        id="loginModal"
        role="dialog"
        aria-labelledby="loginModal"
        aria-hidden="true"
      >
        <DivModal className="modal-dialog" role="document">
          <div className="modal-content" style={{ borderRadius: "15px" }}>
            <div
              className="modal-body text-center text-small"
              style={{
                background: "#9B44FF",
                borderRadius: "15px",
                padding: "15px",
              }}
            >
              <span
                className="iconify"
                data-icon="uil:18-plus"
                data-inline="false"
                data-width={window.innerWidth <= 576 ? "20px" : "30px"}
                style={{
                  position: "absolute",
                  bottom: 10,
                  right: 10,
                  color: "#fff",
                }}
              ></span>
              <div className="">
                <Animated animationIn="" isVisible={true}>
                  <Form
                    autoComplete="off"
                    className="my-3"
                    onSubmit={this.handleSubmit}
                    noValidate
                    style={{ color: "#fff", fontFamily: "Roboto, sans-serif" }}
                  >
                    <FormGroup autoComplete="off" style={formGroupStyles}>
                      <ParagraphLabel>
                        CUIL/DNI/Correo electrónico
                      </ParagraphLabel>
                      <div style={{ position: "relative" }}>
                        <SpanIcon
                          className="iconify"
                          data-icon="mdi:user"
                          data-inline="false"
                          data-width="20px"
                        ></SpanIcon>
                        <Input
                          autoComplete="off"
                          type="text"
                          value={this.state.nroDocu}
                          name="nroDocu"
                          id="nroDocu"
                          style={{
                            ...inputStyles,
                            padding: "0 0 0 34px",
                          }}
                          data-inline="false"
                          onChange={this.handleChange}
                        />
                      </div>
                      <ParagraphErrorText className="text-small">
                        {this.state.errors.nroDocu}
                      </ParagraphErrorText>
                    </FormGroup>

                    <FormGroup autoComplete="off" style={formGroupStyles}>
                      <ParagraphLabel>Contraseña</ParagraphLabel>
                      <div style={{ position: "relative" }}>
                        <SpanIcon
                          className="iconify"
                          data-icon="material-symbols:lock"
                          data-inline="false"
                          data-width="20px"
                        ></SpanIcon>
                        <Input
                          className="form-control mr-0 text-pass"
                          value={this.state.clave}
                          autoComplete="off"
                          type="text"
                          name="clave"
                          id="clave"
                          onCopy={(e) => {
                            e.preventDefault();
                            e.nativeEvent.stopImmediatePropagation();
                          }}
                          onCut={(e) => {
                            e.preventDefault();
                            e.nativeEvent.stopImmediatePropagation();
                          }}
                          onChange={this.handleChange}
                          style={{
                            ...inputStyles,
                            padding: "0 34px",
                          }}
                        />
                        <div onClick={this.handlePassView}>
                          <Animated
                            animationIn="fadeIn"
                            animationInDuration={200}
                            animationOutDuration={200}
                            animateOnMount={false}
                            isVisible={!this.state.pass}
                            style={eyeStyles}
                          >
                            <span
                              id="eye"
                              data-width="20px"
                              className="iconify op-text"
                              data-icon="mdi:eye-off"
                              style={{ color: "#9b44ff" }}
                              data-inline="false"
                            ></span>
                          </Animated>
                          <Animated
                            animationIn="fadeIn"
                            animationInDuration={200}
                            animationOutDuration={200}
                            animateOnMount={false}
                            isVisible={this.state.pass}
                            style={eyeStyles}
                          >
                            <span
                              id="eye"
                              data-width="20px"
                              className="iconify op-text"
                              style={{ color: "#9b44ff" }}
                              data-icon="mdi:eye"
                              data-inline="false"
                            ></span>
                          </Animated>
                        </div>
                      </div>
                      <ParagraphErrorText className="text-small">
                        {this.state.errors.clave}
                      </ParagraphErrorText>
                    </FormGroup>

                    <FormGroup
                      check
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <label className="switch">
                        <input
                          checked={this.state.checked}
                          type="checkbox"
                          id="remember_me"
                          onChange={() => {
                            this.props.handleRemember("toggle");
                            this.uncheck();
                          }}
                        />
                        <span
                          className={
                            "sliderLogin round sliderLogin-" +
                            process.env.REACT_APP_COD_ORGANIZACION
                          }
                        ></span>
                      </label>
                      <div
                        className="mt-2 ml-2"
                        style={{ fontSize: "1.1rem", marginBottom: "20px" }}
                      >
                        Recordarme
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div id="IngresoCaptcha" tabIndex="1">
                        {jsxCaptcha}
                      </div>
                    </FormGroup>

                    <ButtonSalta
                      variant="light"
                      id={"botonLogin"}
                      style={{ margin: "10px 0" }}
                    >
                      {this.state.cargando === "EJECUTANDO" && (
                        <div className="animate-flicker">
                          <b>Cargando</b>
                        </div>
                      )}

                      {(this.state.cargando === "INICIO" ||
                        this.state.cargando === "FIN") && <span>Ingresar</span>}
                    </ButtonSalta>

                    <div
                      onClick={() => {
                        this.setState({ envioGeneracion: false });
                        $(".nroDocuOlvide").val("");
                      }}
                      style={{ cursor: "pointer", fontSize: "1.1rem" }}
                    >
                      <div data-toggle="modal" data-target="#olvideModal">
                        ¿Olvidaste tu contraseña?
                      </div>
                    </div>

                    <ButtonSalta
                      variant="primary"
                      onClick={this.irARegistracion}
                      style={{ margin: "20px 0" }}
                    >
                      Registrarse
                    </ButtonSalta>

                    <ParagraphLabel
                      style={{ fontSize: ".9rem", textAlign: "center" }}
                    >
                      Juguemos con responsabilidad y en sitios oficiales
                    </ParagraphLabel>
                  </Form>
                </Animated>

                {/*---------------------------------------MODAL OLVIDE PASS-----------------------------------------------------*/}
                <div
                  style={{ position: "fixed", top: "8%" }}
                  className="modal fade pb-5"
                  id="olvideModal"
                  role="dialog"
                  aria-labelledby="olvideModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="olvideModalLabel">
                          Generar nueva clave
                        </h5>

                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div className="modal-body text-center pt-0">
                        <OlvideClave
                          uncheck={this.uncheck}
                          handleRemember={this.props.handleRemember}
                          generoNuevaClave={this.generoNuevaClave}
                          nroDocuEnviado={this.state.envioGeneracion}
                          notificacionMensajes={this.props.notificacionMensajes}
                          actuVersionSiCorresponde={
                            this.props.actuVersionSiCorresponde
                          }
                          mensajeErrorGeneral={this.props.mensajeErrorGeneral}
                          mensajeErrorWS={this.props.mensajeErrorWS}
                          cerrarSesion={this.props.cerrarSesion}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DivModal>
      </div>
    );
  }
}

export default withRouter(ModalLoginSalta);

const DivModal = styled.div`
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important; */
  box-shadow: none !important;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SpanIcon = styled.span`
  position: absolute;
  top: 8px;
  left: 10px;
  color: #9b44ff;
`;

const ParagraphErrorText = styled.p`
  color: #fb7e7e;
  margin: 0;
  text-align: left;
`;

const ParagraphLabel = styled.p`
  text-align: left;
  margin: 0;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem;
`;
