import React, { useState } from "react";
import styled from "styled-components";
import { OpenAndCloseEye } from "./CommonsSalta";

const admittedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab"];

const InputSalta = ({
  placeholder,
  id,
  label,
  value,
  setValue,
  type,
  required,
  withDecimals,
  helperText,
  errorMessage,
  isCuil,
  handleCheckErrors,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputType =
    type === "password" ? (showPassword ? "text" : "password") : type;

  const handleKeyDown = (event) => {
    const { key } = event;

    if (type === "number") {
      if (withDecimals) {
        // Allow numbers, one ".", and prevent more than two decimals
        if (
          !/^\d$/.test(key) &&
          !admittedKeys.includes(key) &&
          (key !== "." || value.includes("."))
        ) {
          event.preventDefault();
        }

        // Ensure only two decimal places are allowed
        if (
          value?.includes(".") &&
          value?.split(".")[1].length >= 2 &&
          /^\d$/.test(key)
        ) {
          event.preventDefault();
        }
      } else if (!/^\d$/.test(key) && !admittedKeys.includes(key)) {
        // Only allow numbers
        event.preventDefault();
      }
    }
  };

  const formatCuil = (input) => {
    const onlyNumbers = input.replace(/\D/g, "").slice(0, 11); // Max 11 digits
    if (onlyNumbers.length <= 2) {
      return onlyNumbers;
    } else if (onlyNumbers.length <= 10) {
      return `${onlyNumbers.slice(0, 2)}-${onlyNumbers.slice(2)}`;
    } else {
      return `${onlyNumbers.slice(0, 2)}-${onlyNumbers.slice(
        2,
        10
      )}-${onlyNumbers.slice(10)}`;
    }
  };

  const handleChange = (value) => {
    if (isCuil) {
      const formattedValue = formatCuil(value);
      handleCheckErrors(id, formattedValue);
      setValue(formattedValue);
      return;
    }
    handleCheckErrors(id, value);
    setValue(value);
  };

  return (
    <div style={{ margin: "12px 0" }}>
      <div style={{ position: "relative" }}>
        <InputCustom
          type={inputType}
          id={id}
          required={required}
          placeholder={placeholder}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          handleKeyDown={handleKeyDown}
          autoComplete="new-password"
        />
        {type === "password" && (
          <EyeIcon onClick={() => setShowPassword((prev) => !prev)}>
            <OpenAndCloseEye
              passVisible={showPassword}
              styles={{ position: "absolute", top: -13, right: -5 }}
            />
          </EyeIcon>
        )}
        <LabelCustom for={id}>
          {label} {required && " *"}{" "}
        </LabelCustom>
      </div>
      {helperText && <HelperTextCustom>{helperText}</HelperTextCustom>}
      {errorMessage && <ErrorMsgCustom>{errorMessage}</ErrorMsgCustom>}
    </div>
  );
};

export default InputSalta;

const LabelCustom = styled.label`
  bottom: 22px;
  left: 10px;
  position: absolute;
  background-color: #fef7ff;
  padding: 0px 5px 0px 5px;
  font-size: 0.9rem;
  transition: 0.2s;
  pointer-events: none;
  color: #49454f;
`;

const EyeIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  user-select: none;
`;

const InputCustom = styled.input`
  display: block;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #79747e;
  padding: 8px 26px 8px 14px;
  &::placeholder {
    color: #d0d5da;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

const HelperTextCustom = styled.p`
  color: #49454f;
  font-size: 0.8rem;
  margin: 0;
  padding: 0 14px;
`;

const ErrorMsgCustom = styled.p`
  color: rgb(218, 3, 3);
  font-size: 0.8rem;
  margin: 5px 0;
  padding: 0 14px;
`;

export const InputSelectSalta = ({
  id,
  label,
  value,
  setValue,
  options,
  required,
  helperText,
  errorMessage,
  handleCheckErrors,
}) => {
  return (
    <div style={{ margin: "12px 0" }}>
      <div style={{ position: "relative" }}>
        <SelectCustom
          id={id}
          value={value}
          onChange={(e) => {
            handleCheckErrors(id, e.target.value);
            setValue(e.target.value);
          }}
          required={required}
        >
          <option disabled selected value={undefined}>
            Seleccione una opción
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectCustom>
        <SelectIcon>
          <span
            data-width="15px"
            className="iconify op-text"
            data-icon="bxs:down-arrow"
            style={{ color: "#9b44ff" }}
            data-inline="false"
          ></span>
        </SelectIcon>
        <LabelCustom htmlFor={id}>
          {label} {required && " *"}
        </LabelCustom>
      </div>
      {helperText && <HelperTextCustom>{helperText}</HelperTextCustom>}
      {errorMessage && <ErrorMsgCustom>{errorMessage}</ErrorMsgCustom>}
    </div>
  );
};

const SelectCustom = styled.select`
  display: block;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #79747e;
  padding: 8px 14px;
  background: white;
  appearance: none;
  cursor: pointer;
`;

const SelectIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;
