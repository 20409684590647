import React, { useEffect, useState } from "react";
import {
  DivRow,
  H5Saldo,
  H6SaldoTexto,
} from "../../tools/identificadorQRCliente/PaginaSolicitudRetiro";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { FormGroup, Input, Label } from "reactstrap";
import { estilosProv } from "../../configProvincias";
import ButtonSalta from "./ButtonSalta";
import $ from "jquery";

const commonLabelStyles = {
  fontSize: "1.1rem",
  display: "flex",
  alignItems: "center",
  width: "fit-content",
};

const ExtractionsSalta = ({
  cargando,
  saldo,
  grabandoSolicitud,
  montoConDecimales,
  handleInputBlur,
  setmontoConDecimales,
  clickGenerarSolicitud,
  logueado,
  ultimoCbu,
  setUltimoCbu,
  extractionType,
  setExtractionType,
}) => {
  const stylesByOrg = estilosProv[process.env.REACT_APP_COD_ORGANIZACION];
  const [errorCbu, setErrorCbu] = useState("");

  useEffect(() => {
    // console.log("wahhchc");
    logueado && $("#idMontoSolicitud").focus();
  }, []);

  if (!logueado)
    return (
      <DivMain style={{ position: "relative" }}>
        <NotAllowedDiv onClick={() => $("#loginModal").modal("show")} />
        <DivContainer>
          <ExtractionsSaltaTemplate />
        </DivContainer>
      </DivMain>
    );

  return (
    <DivMain style={{ position: "relative" }}>
      <DivContainer>
        <DivContainerMoney>
          <DivAvailableAmount className="col-12 col-md-6">
            <p className="m-bold">Saldo para extracción</p>
            <div className="">
              {cargando === "FIN" && (
                <H5Saldo
                  className="m-heavy +w-90"
                  style={{ color: stylesByOrg.success }}
                >
                  <NumberFormat
                    value={saldo}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    isNumericString={true}
                  />
                </H5Saldo>
              )}
            </div>
          </DivAvailableAmount>

          <div className="col-12 col-md-6" style={{ padding: "10px 0" }}>
            {cargando !== "EJECUTANDO" && grabandoSolicitud === false && (
              <div>
                <h6>
                  Importe a <span className="m-bold">retirar</span>
                </h6>
                <NumberFormat
                  type="text"
                  autoComplete="off"
                  maxLength={13}
                  isNumericString={true}
                  onChange={handleInputBlur}
                  decimalScale={2}
                  prefix={"$ "}
                  placeholder="$ 150.000"
                  id="idMontoSolicitud"
                  name="fMontoSolicitud"
                  style={{
                    border: "1px solid #D0D5DA",
                    fontWeight: "bold",
                    textAlign: "center",
                    color: stylesByOrg.success,
                    borderRadius: "10px",
                    fontSize: "1.25rem",
                    padding: "6px",
                  }}
                  displayType={"input"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  readOnly={!logueado}
                  onValueChange={(values) => {
                    setmontoConDecimales(values.floatValue);
                  }}
                />
              </div>
            )}
          </div>
          {grabandoSolicitud && (
            <div>
              <b>
                <span style={{ fontSize: "20px" }}>
                  <NumberFormat
                    value={montoConDecimales}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    isNumericString={true}
                  />
                </span>
              </b>
            </div>
          )}
        </DivContainerMoney>

        {montoConDecimales && (
          <DivExtractionType>
            <div className="row px-3">
              <FormGroup check>
                <Label check style={commonLabelStyles}>
                  <Input
                    type="radio"
                    defaultChecked={extractionType === "manually"}
                    name="extractionType"
                    className="input"
                    value="manually"
                    onClick={() => setExtractionType("manually")}
                    style={{ top: "3px" }}
                  />
                  Retiro en punto de venta
                </Label>
              </FormGroup>
            </div>
            <div className="row px-3">
              <FormGroup check className="col-12 col-md-6">
                <Label check style={commonLabelStyles}>
                  <Input
                    type="radio"
                    name="extractionType"
                    defaultChecked={extractionType === "transfer"}
                    className="input"
                    value="transfer"
                    onClick={() => setExtractionType("transfer")}
                    style={{ top: "3px" }}
                  />
                  Transferencia
                </Label>
                <p style={{ color: "#888989", fontSize: ".8rem", margin: "0" }}>
                  Antes de las 12:00pm se acredita en el día.
                </p>
              </FormGroup>
              {montoConDecimales && extractionType === "transfer" && (
                <div className="col-12 col-md-6">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p className="m-bold my-0">
                      CUIL:{" "}
                      <span style={{ margin: "0 0 0 4px" }}>
                        {window.getCuil()}
                      </span>
                    </p>
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                      <div className="d-flex align-items-center">
                        <p className="m-bold my-0 mr-2">CBU/CVU:</p>
                        <NumberFormat
                          type="text"
                          autoComplete="off"
                          maxLength={22}
                          isNumericString={true}
                          decimalScale={0}
                          id="idCBUInput"
                          value={ultimoCbu ? ultimoCbu : null}
                          style={{
                            width: "70%",
                            border: "1px solid #D0D5DA",
                            fontWeight: "bold",
                            textAlign: "center",
                            borderRadius: "10px",
                            fontSize: ".9rem",
                            padding: "2px",
                          }}
                          displayType={"input"}
                          onChange={(event) => {
                            setUltimoCbu(event.target.value)
                            if (event.target.value.length === 0) {
                              setErrorCbu("Este campo es obligatorio");
                            } else if (event.target.value.length < 22) {
                              setErrorCbu("El número debe tener 22 dígitos");
                            } else {
                              setErrorCbu("");
                            }
                          }}
                        />
                      </div>
                      {errorCbu && <div style={{ color: "red", fontSize: "10px" }}>{errorCbu}</div>}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </DivExtractionType>
        )}

        {montoConDecimales && extractionType && (
          <DivActionsContainer>
            <ButtonSalta
              variant="success"
              disabled={
                montoConDecimales === undefined ||
                0 >= montoConDecimales ||
                montoConDecimales > saldo ||
                (extractionType === "transfer" && ultimoCbu.length < 22)
              }
              onClick={clickGenerarSolicitud}
            >
              Enviar
            </ButtonSalta>
          </DivActionsContainer>
        )}
      </DivContainer>
    </DivMain>
  );
};

export default ExtractionsSalta;

const DivContainerMoney = styled.div`
  text-align: center;
  margin-top: 4vh;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  background-color: #f9f4fe;
  padding: 8px;
  flex-wrap: wrap;
`;

const DivAvailableAmount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #ffffff;
  padding: 10px 0;
`;

const DivExtractionType = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
`;

const DivTransferContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DivMain = styled.div`
  width: "100%";
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const DivContainer = styled.div`
  min-width: 40%;
`;

const DivActionsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 15px;
`;

const NotAllowedDiv = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ExtractionsSaltaTemplate = () => {
  const stylesByOrg = estilosProv[process.env.REACT_APP_COD_ORGANIZACION];
  return (
    <DivContainerMoney>
      <DivAvailableAmount className="col-12 col-md-6">
        <p className="m-bold">Saldo para extracción</p>
        <div className="">
          <H5Saldo
            className="m-heavy +w-90"
            style={{ color: stylesByOrg.success }}
          >
            <NumberFormat
              value={"0"}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$ "}
              decimalScale={2}
              fixedDecimalScale={true}
              isNumericString={true}
            />
          </H5Saldo>
        </div>
      </DivAvailableAmount>

      <div className="col-12 col-md-6" style={{ padding: "10px 0" }}>
        <div>
          <h6>
            Importe a <span className="m-bold">retirar</span>
          </h6>
          <NumberFormat
            type="text"
            autoComplete="off"
            maxLength={13}
            isNumericString={true}
            decimalScale={2}
            prefix={"$ "}
            placeholder="$ 150.000"
            style={{
              border: "1px solid #D0D5DA",
              fontWeight: "bold",
              textAlign: "center",
              color: stylesByOrg.success,
              borderRadius: "10px",
              fontSize: "1.25rem",
              padding: "6px",
              width: "80%"
            }}
            displayType={"input"}
            thousandSeparator={"."}
            decimalSeparator={","}
            readOnly
          />
        </div>
      </div>
    </DivContainerMoney>
  );
};
